import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PhotoGalleryComponent from "./PhotoGalleryComponent";
import { wedding } from "./Photos/weddings";
import './PhotoGallery.css';
import { initGA } from '../Tracking';

class WeddingGallery extends Component {
  componentDidMount() {
    initGA();
  }
  constructor(props) {
    super(props);
    this.state = { viewerIsOpen:false , currentImage: 0}
  }
  render() {
    return (
      <Container>
        <PhotoGalleryComponent title="Wedding" photos={wedding.reverse()}/>
      </Container>
    );
  }
}

export default WeddingGallery;