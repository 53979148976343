import React, { Component } from 'react';
import PricingImage from './pricing.jpg';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './Pricing.css';
import { pageView, initGA } from '../Tracking';

class Pricing extends Component {
  componentDidMount() {
    initGA();
    pageView('/pricing', 'Pricing Page')
  }
  render() {
    return (
      <Container>
        <Row>
          <Col md={6}>
            <Row className="h-100">
              <Col className="align-self-center">
                <Image className="pricing-image" src={PricingImage} rounded fluid />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="h-100">
                <Col className="align-self-center pricing-items">
                  <div className="pricing-group">
                    <Col sm={12} className="pricing-type"><p>Pricing for Custom Process</p></Col>
                    <Col sm={12} className="pricing-item"><p>Custom Suit Starts at $890</p></Col>
                    <Col sm={12} className="pricing-item"><p>Custom Three Piece Suit Starts at $1160 (with Vest)</p></Col>
                    <Col sm={12} className="pricing-item"><p>Sport Jacket starts from $650</p></Col>
                    <Col sm={12} className="pricing-item"><p>Trouser starts from $290</p></Col>
                    <Col sm={12} className="pricing-item"><p>Custom Shirt Starts at $150</p></Col>
                    <Col sm={12} className="pricing-item"><p>(Get One Complimentary Shirt for Every Four Shirts Purchased)</p></Col>
                    <Col sm={12} className="pricing-item"><p>Custom Top Coat Starts at $1390</p></Col>
                  </div>
                  <div className="pricing-group">
                    <Col sm={12} className="pricing-type"><p>Pricing for Bespoke Process</p></Col>
                    <Col sm={12} className="pricing-item"><p>Bespoke Suit Starts at $1,700</p></Col>
                    <Col sm={12} className="pricing-item"><p>Bespoke Three Piece Suit Starts at $2,000 (with Vest)</p></Col>
                  </div>
                </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Pricing;
