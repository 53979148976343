import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Process.css';
import { pageView, initGA } from '../Tracking';

class Process extends Component {
  componentDidMount() {
    initGA();
    pageView('/process', 'Process Page')
  }
  render() {
    return (
      <Container>
        <div className="photo">
          <Row>
            <Col className="youtube-container">
              <iframe className="youtube" title="fitting-video" src="https://www.youtube.com/embed/unzTlXBi7JY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Col>
          </Row>
        </div>
        <div className="contexts">
          <div className="step">
            <Row className="process">
              <Col>
                <h2 className="text-left">1. Consultation to Order</h2>
              </Col>
            </Row>
            <Row className="process-context">
              <Col>
                <p className="text-left">
                  We pay close attention to understand your needs as well as your taste during your entire experience at Jacob Young.  We ask various questions about your special occasion, if any, and ask whether you wear suit everyday or time to time for special events such as wedding or black tie event.  Jacob Young professionals are here to simplify the process to assist you with making ideal selections for fabric, construction and options including linings, buttons, and many other details that may sound overwhelming.  Once the selections are made, the next step is to take your body measurements, which involves both quantitative and qualitative assessments.  In addition to measuring your sizes, any body shape concerns you may have is taken into account to access your true fit.  When finalizing your order, you may have the option to choose between the Custom Process and the Bespoke Process depending on your selections and measurements. 
                </p>
              </Col>
            </Row>
          </div>
          <div className="step">
            <Row className="process">
              <Col>
                <h2 className="text-left">2. Fitting to Finish</h2>
              </Col>
            </Row>
            <Row className="process-context">
              <Col>
                <p className="text-left">
                  Custom Process: Estimated timing for first fitting can be as early as 3 weeks from the date you place your order.  At your first fitting, we have you try on your custom made garment and mutually determine if any adjustment is needed.  If you are satisfied and no adjustment is needed, your first fitting becomes your final fitting.  
                </p>
              </Col>
            </Row>
            <Row className="process-context">
              <Col>
                <p className="text-left">
                  Bespoke Process: Estimated timing for first basted fitting can be as early as 3 weeks from the date you place your order.  At your first basted fitting, we have you try on your basted garment to check every detail and pin for any adjustment, if needed.  Once you are satisfied with your basted garment, we then finalize it to be made into a suit.  Estimated timing for first fitting after the basted fitting is about 3 weeks from the date we finalize the basted garment.  At your first bespoke garment fitting, we have you try on your bespoke suit and mutually determine if any additional adjustment is needed.  When you are satisfied and no adjustment is needed, that becomes your final fitting. 
                </p>
              </Col>
            </Row>
          </div>
          <div className="step">
            <Row className="process">
              <Col>
                <h2 className="text-left">3. Fit Guarantee</h2>
              </Col>
            </Row>
            <Row className="process-context">
              <Col>
                <p className="text-left">
                  We need your feedback.  We check in with you a few weeks after your final fitting to make sure you are happy with your Jacob Young garment.  If you have any fit concerns, we happily offer complimentary alteration service within 60 days from your final fitting date.  Jacob Young accommodates your demand to fulfill your satisfaction.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}

export default Process;