import React, { Component } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Row, Col } from 'react-bootstrap';

class PhotoGalleryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { viewerIsOpen:false , currentImage: 0}
  }
  openLightbox = (event, { photo, index }) => {
    this.setState({viewerIsOpen: true, currentImage: index});
  }
  closeLightbox = () => {
    this.setState({viewerIsOpen: false, currentImage: 0});
  }

  render() {
    return (
      <Row>
        <Col className="gallery-type">
          <Row>
            <Col className="gallery-type-name">
              <h3>{this.props.title}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Gallery photos={this.props.photos} onClick={this.openLightbox} />
              <ModalGateway>
                {this.state.viewerIsOpen ? (
                  <Modal onClose={this.closeLightbox}>
                    <Carousel
                      currentIndex={this.state.currentImage}
                      views={this.props.photos.map(x => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default PhotoGalleryComponent;