import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'

import { 
  Home,
  Process,
  Pricing,
  PhotoGallery,
  WeddingGallery,
  Instagram,
  Appointment,
  Promotion
} from '../';

class Contents extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/process' component={Process}/>
          <Route exact path='/pricing' component={Pricing}/>
          <Route exact path='/gallery' component={PhotoGallery}/>
          <Route exact path='/weddings' component={WeddingGallery}/>
          <Route exact path='/promotion' component={Promotion}/>
          <Route exact path='/instagram' component={Instagram}/>
          <Route exact path='/appointment' component={Appointment}/>
        </Switch>
      </div>
    );
  }
}

export default Contents;