import React, { Component } from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import { buildUrl } from 'instafeed-lite'
import './Instagram.css';
import { pageView, initGA } from '../Tracking';

const options = {
  accessToken: process.env.REACT_APP_INSTA_TOKEN,
  clientId: process.env.REACT_APP_INSTA_CLIENT_ID,
  get: 'user', // popular, user
  locationId: null,
  resolution: 'standard_resolution', // thumbnail, low_resolution, standard_resolution
  sortBy: 'none', // none, least-commented, least-liked, least-recent, most-commented, most-liked, most-recent, random
  tagName: null,
  userId: process.env.REACT_APP_INSTA_USER_ID,
}

const instagramUrl = buildUrl(options)

class Instagram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instagramData: []
    };
  }

  componentDidMount() {
    initGA();
    pageView('/instagram', 'Instagram Page')
    fetch(instagramUrl)
    .then(res => res.json())
    .then((data) => {
      this.setState({ instagramData: data.data })
    })
    .catch(console.log)
  }
  render() {
    return (
      <Container>
        <Row>
        {
          this.state.instagramData.map((value, index) => {
            return(
              <Col key={value.id} className="instagram-photo-box">
                <span className="helper"></span>
                <a href={value.link} >
                  <Image className="instagram-photo" src={value.images.low_resolution.url}/>
                </a>
              </Col>
            )
          })
        }
        </Row>
      </Container>
    );
  }
}

export default Instagram;