import React, { Component } from 'react';
import PromotionImage from './2023promo.jpeg';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { pageView, initGA } from '../Tracking';

class Promotion extends Component {
  componentDidMount() {
    initGA();
    pageView('/promotion', 'Promotion Page')
  }
  render() {
    return (
      <Container>
        <Row>
          {/*<Col md={2}/>*/}
          <Col md={12}><Image src={PromotionImage} rounded fluid /></Col>
          {/*<Col md={2}/>*/}
        </Row>
      </Container>
    );
  }
}

export default Promotion;