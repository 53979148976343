export const wedding = [
  {
    src: "/Gallery/weddings/1.jpg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/2.jpg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/3.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/4.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/5.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/6.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/7.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/8.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/9.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/10.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/11.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/12.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/13.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/14.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/15.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/16.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/17.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/18.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/19.jpeg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/weddings/20.jpeg",
    width:4,
    height:3
  },
  {
    src: "/Gallery/weddings/21.jpeg",
    width:4,
    height:3
  },
  {
    src: "/Gallery/weddings/22.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/weddings/23.jpeg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/weddings/24.jpeg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/weddings/25.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/weddings/26.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/weddings/27.jpeg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/weddings/28.JPG",
    width:4,
    height:5
  },
  {
    src: "/Gallery/weddings/29.JPG",
    width:4,
    height:5
  },
  {
    src: "/Gallery/weddings/30.JPG",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/31.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/weddings/32.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/33.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/34.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/35.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/36.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/37.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/38.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/39.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/40.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/41.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/42.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/43.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/44.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/45.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/46.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/47.jpeg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/weddings/48.jpeg",
    width:3,
    height:2
  },
  {
    src: "/Gallery/weddings/49.jpeg",
    width:3,
    height:2
  }
];
