import React, { Component } from 'react';
import { Contents, NavBar } from './components';
import { HashRouter as Router } from 'react-router-dom'
import { Container, Row, Col, Image } from 'react-bootstrap';
import './App.css';
import logo from './images/JacobYoungLogo.png';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <a href="/"><Image className="App-logo" src={logo}/></a>
        </div>
          <Router>
            <NavBar/>
            <Contents/>
          </Router>
        <Container>
          <Row className="mt-5">
            <Col>
              <p className="float-left">© jacob young 2020. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


export default App;
