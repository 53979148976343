import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PhotoGalleryComponent from "./PhotoGalleryComponent";
import { clients, suits, jackets, details } from "./Photos/photos";
import './PhotoGallery.css';
import { pageView, initGA } from '../Tracking';

class PhotoGallery extends Component {
  componentDidMount() {
    initGA();
    pageView('/gallery', 'Gallery Page')
  }
  constructor(props) {
    super(props);
    this.state = { viewerIsOpen:false , currentImage: 0}
  }
  render() {
    return (
      <Container>
        {/*<PhotoGalleryComponent title="On Sale" photos={onsale.reverse()}/>*/}
        <PhotoGalleryComponent title="Clients" photos={clients.reverse()}/>
        <PhotoGalleryComponent title="Suits" photos={suits.reverse()}/>
        <PhotoGalleryComponent title="Jackets" photos={jackets.reverse()}/>
        <PhotoGalleryComponent title="Details" photos={details.reverse()}/>
      </Container>
    );
  }
}

export default PhotoGallery;