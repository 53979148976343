import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AppointmentForm from "./AppointmentForm"
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import "./Appointment.css"
import { pageView, initGA } from '../Tracking';

const center = {
  lat: 41.9222493,
  lng: -87.6470913
};
const MapWithAMarker = () => {
  return (
  <LoadScript googleMapsApiKey="AIzaSyCyRpAqAzDWBbrn8ZEO7DigMkw2z-ueV3g">
    <GoogleMap
      zoom={16}
      center={center}
      mapContainerStyle={{ height: `300px` }}
    >
      <Marker
        position={center}
      />
    </GoogleMap>
  </LoadScript>
)}


class Appointment extends Component {
  componentDidMount() {
    initGA();
    pageView('/appointment', 'Appointment Page');
  }
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      formError: false
    };
  };

  handleSubmitted = (event) => {
    this.setState({submitted: true});
  }

  handleError = (event) => {
    this.setState({formError: true})
  }


  render() {
    return (
      <Container>
      	<h3>Contact / Request Appointment </h3>
        <Row>
          
            {
              this.state.submitted ? 
              <Col className="submission-message-box" xl={7}><span className="helper"></span>{
                this.state.formError ? 
                <div><h2 className="submission-message">Sorry something went wrong.</h2><p>Please contact Jake.lim@jybespoke.com directly or try again later.</p></div> 
                : <div><h2 className="submission-message">Thank you for submitting the form!</h2><p>We will contact you for confirmation</p></div>
              }</Col> 
              : <Col className="appointment-form" xl={7}><AppointmentForm handleSubmit={this.handleSubmitted} handleError={this.handleError}/></Col>
            }
          
          <Col xl={1}></Col>
          <Col className="info">
            <Row className="info-row">
              <Col className="contact-phone">
                <h3>Contact By Phone</h3>
                <p>312.929.9069</p>
              </Col>
            </Row>
            <Row className="info-row">
              <Col className="location">
                <h3>Location</h3>
                <p>2214 N Lincoln Ave Chicago, IL 60614</p>
              </Col>
            </Row>
            <Row className="info-row">
              <Col className="google-map">
                <MapWithAMarker/>
              </Col>
            </Row>
            <Row className="info-row">
              <Col className="hours">
                <p>Sunday  Closed</p>
                <p>Monday Closed(Traveling Service Available Contact us) </p>
                <p>Tuesday 10:30 AM - 5:30 PM</p>
                <p>Wednesday 10:30 AM - 5:30 PM</p>
                <p>Thursday 10:30 AM - 5:30 PM</p>
                <p>Friday  10:30 AM - 5:30 PM</p>
                <p>Saturday 10:00 AM - 4:00 PM</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Appointment;