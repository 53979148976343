export const suits = [
  {
    src: "/Gallery/suits/1.png",
    width:1,
    height:1
  },
  {
    src: "/Gallery/suits/5.png",
    width:4,
    height:5
  },
  {
    src: "/Gallery/suits/6.jpg",
    width:4,
    height:5
  },
  {
    src: "/Gallery/suits/7.jpg",
    width:1,
    height:2
  },
  {
    src: "/Gallery/suits/8.jpg",
    width:1,
    height:2
  },
  {
    src: "/Gallery/suits/9.jpg",
    width:1,
    height:2
  },
  {
    src: "/Gallery/suits/10.jpg",
    width:1,
    height:2
  }
];

export const jackets = [
  {
    src: "/Gallery/jackets/7.jpg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/jackets/8.jpg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/jackets/9.jpg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/jackets/10.jpg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/jackets/11.jpg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/jackets/12.jpg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/jackets/13.jpg",
    width:2,
    height:3
  },
  {
    src: "/Gallery/jackets/14.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/jackets/15.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/jackets/16.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/jackets/17.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/jackets/18.jpeg",
    width:2,
    height:3
  }
];

export const details = [
  {
    src: "/Gallery/details/1.png",
    width:1,
    height:1
  },
  {
    src: "/Gallery/details/2.png",
    width:4,
    height:3
  },
  {
    src: "/Gallery/details/3.png",
    width:4,
    height:3
  },
  {
    src: "/Gallery/details/4.png",
    width:4,
    height:3
  },
  {
    src: "/Gallery/details/5.png",
    width:4,
    height:3
  },
  {
    src: "/Gallery/details/6.png",
    width:1,
    height:1
  },
  {
    src: "/Gallery/details/7.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/details/8.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/details/9.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/details/10.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/details/11.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/details/12.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/details/13.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/details/14.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/details/15.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/details/16.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/details/17.jpg",
    width:3,
    height:4
  }
];

export const clients = [
  {
    src: "/Gallery/clients/1.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/2.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/3.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/4.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/5.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/6.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/7.png",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/8.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/9.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/10.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/11.png",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/12.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/13.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/14.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/15.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/16.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/17.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/18.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/19.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/20.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/21.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/22.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/23.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/24.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/25.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/26.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/27.jpeg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/28.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/29.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/30.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/31.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/32.jpg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/33.jpg",
    width:3,
    height:4
  },
  {
    src: "/Gallery/clients/34.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/35.jpeg",
    width:1,
    height:1
  },
  {
    src: "/Gallery/clients/36.jpeg",
    width:1,
    height:1
  }

];
