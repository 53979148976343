import React, { Component } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Appointment.css";
import { Formik } from 'formik';
import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const schema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  firstName: Yup.string()
    .required('First Name is required'),
  lastName: Yup.string()
    .required('Last Name is required'),
  appointmentDate: Yup.date()
    .required('Appointment Date is required'),
  eventDate: Yup.date(),
  appointmentTime: Yup.string()
    .required('Appointment Time is required')
})


class AppointmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDay: 0,
      isBlackOut: false
    };
    this.availableTimeSlots = {
      0: [],
      1: [],
      2: ['11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM'],
      3: ['11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM'],
      4: ['11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM'],
      5: ['11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM'],
      6: ['10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM']
    }
    this.blackoutDates = new Set([
      '2023-01-24',
      '2023-01-25',
      '2023-01-26',
      '2023-01-27',
      '2023-01-28',
      '2023-01-29',
      '2023-01-30',
      '2023-01-31',
      '2023-02-01',
      '2023-02-02',
      '2023-02-03',
      '2023-02-04',
      '2023-02-05',
      '2023-02-06',
      '2023-02-07',
      '2023-02-08'
    ])
  }

  render() {
    return (
      <Formik
      validationSchema={schema}
      onSubmit={
        (form) => {
            fetch(`${process.env.REACT_APP_API}api/appointment`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(form)
          })
          .then(this.props.handleSubmit())
          .catch(error => {
            this.props.handleError()
          })
        }
      }
      initialValues={{ 
        firstName: '',
        lastName: '',
        appointmentDate: '',
        eventDate:'',
        appointmentTime:'',
        email:'',
        phoneNumber:'',
        comments:'',
        howHeard:{
           google: false,
           yelp: false,
           instagram: false,
           facebook: false,
           referral: false,
           passing_by_store: false,
           email: false,
           other: false
        }
       }}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        validateForm,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="appointment-form">
          <Form.Row className="appointment-form-row">
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>First Name *</Form.Label>
              <Form.Control type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                isInvalid={errors.firstName} />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridLastName">
              <Form.Label>Last Name *</Form.Label>
              <Form.Control type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                isInvalid={errors.lastName} />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row className="appointment-form-row">
            <Form.Group as={Col} controlId="formAppointmentDate" className="appointment-form-row">
              <Form.Label>Preferred Appointment Date *</Form.Label>
              <DatePicker 
                id="formAppointmentDate" 
                className={"form-control " + (errors.appointmentDate ? "is-invalid" : "")}
                name="appointmentDate"
                value={values.appointmentDate}
                selected={values.appointmentDate} 
                onChange={(date) => {
                  let stringDate = date.toISOString().slice(0, 10);
                  let isBlackOut = this.blackoutDates.has(stringDate);
                  values.appointmentDate = date;
                  let currentDay = date ? date.getDay() : 0;
                  this.setState({
                    currentDay: currentDay,
                    isBlackOut: isBlackOut
                  });
                  if(this.availableTimeSlots[currentDay] && !isBlackOut) {
                    values.appointmentTime = this.availableTimeSlots[currentDay][0]
                  } else {
                    values.appointmentTime = ''
                  }
                  validateForm();
                }} 
              />
              <Form.Control.Feedback style={{display: errors.appointmentDate ? 'block' : 'none' }} type="invalid">
                {errors.appointmentDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formEventDate" className="appointment-form-row">
              <Form.Label>Event Date (If applicable)</Form.Label>
              <DatePicker 
                id="formEventDate" 
                className="form-control"
                name="eventDate"
                value={values.eventDate}
                selected={values.eventDate} 
                onChange={(date) => {
                  values.eventDate = date;
                  validateForm();
                }} 
                isInvalid={errors.eventDate} />
              <Form.Control.Feedback type="invalid">
                {errors.eventDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Group controlId="formAppointmentTime" className="appointment-form-row">
            <Form.Label>Preferred Appointment Time *</Form.Label>
            <Form.Control 
              as="select"
              name="appointmentTime"
              value={values.appointmentTime}
              onChange={handleChange}
              isInvalid={errors.appointmentTime} >
              {
                this.availableTimeSlots[this.state.currentDay].map((value, index) => {
                  if(!this.state.isBlackOut)
                    return <option key={value}>{value}</option>
                })
              }
            </Form.Control>
            <Form.Control.Feedback type="invalid">
                {errors.appointmentTime}
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formEmail" className="appointment-form-row">
            <Form.Label>Email Address *</Form.Label>
            <Form.Control 
              type="email" 
              placeholder="Enter email address"
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={errors.email} />
            <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPhone" className="appointment-form-row">
            <Form.Label>Phone Number *</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter phone number"
              name="phoneNumber"
              value={values.phoneNumber}
              onChange={handleChange}
              isInvalid={errors.phoneNumber} />
            <Form.Control.Feedback type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formComments" className="appointment-form-row">
            <Form.Label>Comments</Form.Label>
            <Form.Control 
              as="textarea" 
              aria-label="With textarea" 
              rows="3"
              name="comments"
              value={values.comments}
              onChange={handleChange}
              isInvalid={errors.comments} />
            <Form.Control.Feedback type="invalid">
                {errors.comments}
              </Form.Control.Feedback>
          </Form.Group>

          <fieldset>
             <Form.Group controlId="formHow" className="appointment-form-row">
              <Form.Label>How did you hear about us</Form.Label>
              <Row>
                <Col md={4}/>
                <Col className="checkboxs" md={4}>
                  <Form.Check
                    label="google"
                    name="google"
                    id="google-check"
                    onChange={(e) =>{
                      values.howHeard.google = !values.howHeard.google
                    }}
                  />
                  <Form.Check
                    label="yelp"
                    name="yelp"
                    id="yelp-check"
                    onChange={(e) =>{
                      values.howHeard.yelp = !values.howHeard.yelp
                    }}
                  />
                  <Form.Check
                    label="instagram"
                    name="instagram"
                    id="instagram-check"
                    onChange={(e) =>{
                      values.howHeard.instagram = !values.howHeard.instagram
                    }}
                  />
                  <Form.Check
                    label="facebook"
                    name="facebook"
                    id="facebook-check"
                    onChange={(e) =>{
                      values.howHeard.facebook = !values.howHeard.facebook
                    }}
                  />
                  <Form.Check
                    label="referral"
                    name="referral"
                    id="referral-check"
                    onChange={(e) =>{
                      values.howHeard.referral = !values.howHeard.referral
                    }}
                  />
                  <Form.Check
                    label="passing by store"
                    name="passing_by_store"
                    id="passing_by_store-check"
                    onChange={(e) =>{
                      values.howHeard.passing_by_store = !values.howHeard.passing_by_store
                    }}
                  />
                  <Form.Check
                    label="email"
                    name="email"
                    id="email-check"
                    onChange={(e) =>{
                      values.howHeard.email = !values.howHeard.email
                    }}
                  />
                  <Form.Check
                    label="other"
                    name="other"
                    id="other-check"
                    onChange={(e) =>{
                      values.howHeard.other = !values.howHeard.other
                    }}
                  />
                </Col>
                <Col md={4}/>
              </Row>
            </Form.Group>
          </fieldset>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      )}
      </Formik>
    );
  }
}

export default AppointmentForm;
